import { documentLocationUrl, USER_ROLES } from 'constant';
import { Environment, IData, IJsonData } from 'types';

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const prepareData = (
    { data, userRoles = [USER_ROLES.BASIC_ACCESS] }:
    { data: IJsonData, userRoles?: string[] },
) => {
    const allArticles: IData = {};

    Object.keys(data).forEach((category) => {
        allArticles[category] = data[category].map((article) => ({
            title: article.name,
            category,
            previewText: article.previewText || 'preview text demo',
            videos: article.videos,
            params: article.params,
        }));
    });

    return Object.keys(allArticles).reduce((acc, category) => {
        const articles = allArticles[category].filter((article) => {
            if (article.params.roles) {
                return article.params.roles.some((role) => userRoles.includes(role));
            }

            return true;
        });

        return { ...acc, [category]: articles };
    }, {});
};

export const getEnvironmentFromUrl = (): Environment => {
    if (documentLocationUrl.includes('dev') || documentLocationUrl.includes('localhost')) {
        return 'DEV';
    } else if (documentLocationUrl.includes('test')) {
        return 'TEST';
    } else if (documentLocationUrl.includes('pre')) {
        return 'PRE';
    } else if (documentLocationUrl.includes('poc')) {
        return 'POC';
    } else if (documentLocationUrl.includes('demo')) {
        return 'DEMO';
    } else {
        return 'PROD';
    }
};

export const findArticleByStoplightId = (stoplightId: string, contextData: IData) => {
    const allArticles = Object.values(contextData).flat();

    return allArticles.find((article) => article.params.stoplightId === stoplightId);
};
