import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { categoryTitles } from 'constant';
import { capitalizeFirstLetter } from 'lib/utils';
import cn from 'classnames';
import styles from './Breadcrumbs.module.scss';

export function Breadcrumbs({ isCategory = false, className = '' }) {
    const { category, article } = useParams();
    const categoryTitle = category && categoryTitles[category] ? categoryTitles[category] : category;
    const articleTitle = article ? article.replaceAll('_', ' ').replaceAll('-', ' ') : '';

    return (
        <div className={cn(styles.wrapper, className)}>
            <Link to="/">Home</Link>
            {' > '}
            <Link to={`/${category}`}>{categoryTitle}</Link>
            {!isCategory && ' > '}
            {!isCategory && <Link to={`/${category}/${article}`}>{capitalizeFirstLetter(articleTitle)}</Link>}
        </div>
    );
}
