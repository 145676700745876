import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'shared-components/Button';
import styles from 'styles/ErrorPage.module.scss';

export const UnknownError = () => {
    return (
        <div className={styles.wrapper}>
            <img src="/assets/images/unknown-error.svg" alt="Not Found" />
            <div className={styles.title}>Whoops!</div>
            <div className={styles.description}>
                Something went wrong.
                <br />
                Go back to the home screen or create a support ticket.
            </div>
            <Link to="/"><Button>Return to home screen</Button></Link>
        </div>
    );
};
