import React from 'react';
import { Link } from 'react-router-dom';
import { categories, categoryBg, categoryIcons } from 'constant';
import styles from './Card.module.scss';

interface CardProps {
    title: string;
    category: string;
    previewText: string;
    videos?: string[];
 }

export function Card({
    title, category, previewText, videos = [],
}: CardProps) {
    const categoryIcon = categoryIcons[category] || categoryIcons.default;
    const videoIcon = categoryIcons['video'];
    const categoryBackground = categoryBg[category] || categoryBg.default;
    const to = `/${category}/${title}`;
    const hasVideos = videos?.length > 0 || category === 'video';

    return (
        <div className={styles.wrapper}>
            <Link
                to={to}
                className={styles.imgWrapper}
                style={(category !== categories.video)
                    ? { backgroundImage: `url(${hasVideos ? null : categoryBackground})` } : {}}
            >
                <img
                    src={hasVideos ? videoIcon : categoryIcon}
                    style={hasVideos ? {
                        height: 35,
                    } : {}}
                    className={styles.img}
                    alt="icon"
                />
                <div className={styles.preview}>
                    {previewText}
                </div>

            </Link>
            <Link to={to} className={styles.link}>{title}</Link>
        </div>
    );
}
