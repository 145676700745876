const {
    REACT_APP_SKYCELL_ASSETS_PATH: assetsPath, // https://assets.dev.skymind.com,
} = process.env;

const iconsPath = `${assetsPath}/assets/knowledge-base/images/icons/`;

const icons = {
    approvals: `${iconsPath}approvals.svg`,
    approvals_light: `${iconsPath}approvals-light.svg`,
    orders: `${iconsPath}orders.svg`,
    orders_light: `${iconsPath}orders-light.svg`,
    myCompany: `${iconsPath}my_company.svg`,
    myCompany_light: `${iconsPath}my_company-light.svg`,
    assetManagement: `${iconsPath}asset_management.svg`,
    assetManagement_light: `${iconsPath}asset_management-light.svg`,
    video: `${iconsPath}video.svg`,
    video_light: `${iconsPath}video_light.svg`,
    system: `${iconsPath}system.svg`,
    system_light: `${iconsPath}system-light.svg`,
};

export default icons;
