import React, { useContext, useEffect, useState } from 'react';
import { SectionTitle } from 'components/SectionTitle';
import { DataContext } from 'Contexts/DataContext';
import styles from './Articles.module.scss';
import { Card } from './Card';

// TODO: Remove this component

export function ArticlesMainPage({ title }: { title: string }) {
    const { data } = useContext(DataContext);
    const dataArticles = Object.values(data).flat();
    const allArticles = [...dataArticles];
    const [visibleCount, setVisibleCount] = useState(12); // Initially display 12 cards
    const [isFetching, setIsFetching] = useState(false);

    const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 10 && !isFetching) {
            setIsFetching(true);
        }
    };

    useEffect(() => {
        if (!isFetching) return;

        const loadMoreArticles = () => {
            setVisibleCount((prevCount) => prevCount + 12);
            setIsFetching(false);
        };

        loadMoreArticles();
    }, [isFetching]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className={styles.wrapper}>
            <SectionTitle title={title} className={styles.title} />

            <div className={styles.cards}>
                {allArticles.slice(0, visibleCount).map((article) => (
                    <Card key={article.title} {...article} />
                ))}
            </div>

            {isFetching && <div>Loading more articles...</div>}
        </section>
    );
}
