import React, { useEffect, useRef } from 'react';
import { Input } from 'shared-components/Input';
import SearchIcon from '@mui/icons-material/Search';
import styles from './Search.module.scss';

interface SearchProps {
    searchInput: string;
    setSearchInput: (value: string | null) => void;
}

export const MIN_SEARCH_LENGTH = 3;

const Search = ({ searchInput = '', setSearchInput }: SearchProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <>
            <div className={styles.wrapper}>
                <Input
                    placeholder="How can we help?"
                    className={styles.searchInput}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    ref={inputRef}
                />
                <div className={styles.icon}>
                    <SearchIcon fontSize="small" />
                </div>
            </div>
            {searchInput && searchInput.length < MIN_SEARCH_LENGTH && (
                <div className={styles.hint}>
                    Please enter at least
                    {' '}
                    {MIN_SEARCH_LENGTH}
                    {' '}
                    characters
                </div>
            )}
        </>
    );
};

export default Search;
