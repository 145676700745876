import React, { createContext, useEffect, useMemo, useState } from 'react';
import dataJSON from 'data.json';
import { prepareData } from 'lib/utils';
import { IData } from 'types';
import { language, USER_ROLES } from 'constant';

const ONE_HOUR = 60 * 60 * 1000;

export const DataContext = createContext<{ data: IData, userRoles: string[] }>({
    data: {},
    userRoles: [USER_ROLES.BASIC_ACCESS],
});

function Data({ children }: { children: React.ReactNode }) {
    const [data, setData] = useState<IData>({});
    const [userRoles, setUserRoles] = useState<string[]>([USER_ROLES.BASIC_ACCESS]);

    global.console.log('🚀 ~ Data ~ userRoles:', userRoles);

    const handleMessage = (event: MessageEvent) => {
        const { userRoles: userRolesFromSkyMind } = event.data;

        if (userRolesFromSkyMind) {
            setUserRoles(userRolesFromSkyMind);
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
    }, []);

    useEffect(() => {
        try {
            const fetchData = () => {
                const preparedData = prepareData({ data: dataJSON[language], userRoles });

                setData(preparedData);
                localStorage.setItem('data', JSON.stringify(preparedData));
                localStorage.setItem('lastUpdated', Date.now().toString());
                localStorage.setItem('userRoles', JSON.stringify(userRoles));
            };

            const storedData = localStorage.getItem('data');
            const lastUpdated = localStorage.getItem('lastUpdated');
            const storedUserRoles = localStorage.getItem('userRoles');

            if (!storedUserRoles || storedUserRoles !== JSON.stringify(userRoles)) {
                fetchData();
            } else if (storedData && lastUpdated && Date.now() - parseInt(lastUpdated, 10) < ONE_HOUR) {
                setData(JSON.parse(storedData));
            } else {
                fetchData();
            }
        } catch (error) {
            global.console.error('Error while parsing user roles from SkyMind:', error);
        }
    }, [userRoles]);

    const value = useMemo(
        () => ({ data, userRoles }),
        [data, userRoles],
    );

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
}

export default Data;
