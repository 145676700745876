import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DataContext } from 'Contexts/DataContext';
import { Articles } from 'sections/Articles';
import { IArticle } from 'types';
import cn from 'classnames';
import { NotFound, UnknownError } from 'components/ErrorPage';
import debounce from 'debounce';
import CircularProgress from '@mui/material/CircularProgress';
import { MIN_SEARCH_LENGTH } from 'components/Search/Search';
import { useSearchContext } from 'Contexts/SearchContext';
import styles from './SearchResults.module.scss';
import { decodeBase64 } from './lib';

const {
    REACT_APP_SKYCELL_AI_SEARCH_PATH: url,
    REACT_APP_SKYCELL_AI_SEARCH_API_KEY: apiKey,
} = process.env;

interface ISearchResult {
    articleTitle: string;
    category: string;
}

export const SearchResults = () => {
    const { data: contextData } = useContext(DataContext);
    const { searchInput } = useSearchContext();
    const [articles, setArticles] = useState<IArticle[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const getArticles = useCallback(async (searchQuery: string | null) => {
        if (searchQuery && searchQuery.length < MIN_SEARCH_LENGTH) return;

        setIsLoading(true);
        setIsError(false);
        try {
            const allArticles = Object.values(contextData).flat();

            if (!searchQuery) {
                setArticles(allArticles);
                setIsLoading(false);
                return;
            }

            const response = await fetch(`${url}?search=${
                searchQuery.includes(' ') || searchQuery.includes('*') ? searchQuery
                    : `${searchQuery}*`
            }&api-version=2023-11-01&api-key=${apiKey}`);
            const responseData = await response.json();

            const searchResults: ISearchResult[] = responseData.value.map((article: any) => {
                const rawArticleUrl = decodeBase64(article.metadata_storage_path);
                let articleUrl = rawArticleUrl.replace(/\0/g, '');

                if (articleUrl.includes('/articles/')) {
                    articleUrl = articleUrl.replace(/.*\/articles\//, '');
                    articleUrl = articleUrl.replace(/%20/g, ' ');
                    articleUrl = articleUrl.replace('.md\r', '.html');
                    const [, category, articleTitle] = articleUrl.split('/');

                    return {
                        articleTitle,
                        category,
                    };
                } else {
                    return null;
                }
            }).filter((result: ISearchResult | null) => result !== null);

            const foundArticles = allArticles.filter((article) => {
                return searchResults.some(result => (
                    result.articleTitle.toLowerCase().includes(article.title.toLowerCase())
                    && result.category.toLowerCase() === article.category.toLowerCase()),
                );
            });

            setArticles(foundArticles);
        } catch (error) {
            global.console.error('Error fetching articles:', error);
            setIsError(true);
        }
        setIsLoading(false);
    }, [contextData, setArticles, setIsLoading, setIsError, url, apiKey]);

    const debouncedGetArticles = useCallback(debounce(getArticles, 500), [getArticles]);

    useEffect(() => {
        if (Object.keys(contextData).length === 0) return;

        debouncedGetArticles(searchInput); // Updated to use searchInput

        return () => {
            debouncedGetArticles.clear();
        };
    }, [contextData, searchInput]); // Updated to use searchInput

    if (isLoading || articles === null) {
        return <div className={cn(styles.wrapper, styles.loading)}><CircularProgress /></div>;
    }

    if (isError) {
        return <div className={styles.wrapper}><UnknownError /></div>;
    }

    return (
        <div className={styles.wrapper}>
            {articles && articles.length > 0 ? (
                <Articles
                    articles={articles}
                    title="Documentation Articles"
                />
            ) : (
                <NotFound />
            )}
        </div>
    );
};
