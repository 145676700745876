import React, { useContext, useMemo } from 'react';
import { DataContext } from 'Contexts/DataContext';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { UnknownError } from 'components/ErrorPage';
import { useParams } from 'react-router-dom';
import { Articles } from 'sections/Articles';

export function Category() {
    const { category } = useParams();
    const { data } = useContext(DataContext);

    const articles = useMemo(() => {
        if (category && data && data[category]) {
            return data[category];
        }

        return [];
    }, [category, data]);

    return (
        <div>
            {
                articles.length
                    ? (
                        <>
                            <Breadcrumbs isCategory />
                            <Articles articles={articles} title={category || 'No category'} />
                        </>
                    )
                    : <UnknownError />
            }

        </div>
    );
}
