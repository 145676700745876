import React, { useEffect, useState } from 'react';
import Search from 'components/Search';
import { Container } from 'components/Container';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchContext } from 'Contexts/SearchContext';
import { Breadcrumbs } from 'components/Breadcrumbs';
import BackToLink from 'shared-components/BackToLink';
import containerStyles from 'components/Container/Container.module.scss';
import styles from './Header.module.scss';

export function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isFixedShown, setFixedShown] = useState(false);
    const { searchInput, setSearchInput } = useSearchContext();

    const handleScroll = () => {
        if (window.scrollY > 280) {
            setFixedShown(true);
        } else {
            setFixedShown(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (searchInput === null) return;
        if (searchInput?.length === 0) {
            return navigate('/');
        }

        if (searchInput?.length >= 3) navigate(`/search?query=${searchInput}`);
    }, [searchInput]);

    return (
        <>
            <header className={styles.header}>
                <Container>
                    <h3 className={styles.subTitle}>Knowledge Base</h3>
                    <h2 className={styles.title}>How can we help?</h2>
                    <Search searchInput={searchInput || ''} setSearchInput={setSearchInput} />
                </Container>
            </header>

            {isFixedShown && (
                <header className={cn(styles.header, styles.headerFixed)}>
                    <Container>
                        <h3 className={styles.subTitle}>Knowledge Base</h3>
                        <Search searchInput={searchInput || ''} setSearchInput={setSearchInput} />
                    </Container>
                    {location.pathname !== '/' && (
                        <div className={cn(containerStyles.container, styles.breadcrumbsWrapper)}>
                            <Breadcrumbs className={styles.breadcrumbs} />
                            <BackToLink />
                        </div>
                    )}
                </header>
            )}
        </>
    );
}
