import { DataContext } from 'Contexts/DataContext';
import { findArticleByStoplightId } from 'lib/utils';
import React, { useContext, useEffect, useState } from 'react';
import { Articles } from 'sections/Articles';
import { IArticle } from 'types';

interface Props {
    parentArticleCategory: string;
    parentArticleTitle: string;
}

export const RelatedArticles = ({ parentArticleTitle, parentArticleCategory }: Props) => {
    const { data: contextData } = useContext(DataContext);
    const [relatedArticles, setRelatedArticles] = useState<IArticle[]>([]);

    useEffect(() => {
        if (!parentArticleTitle || !parentArticleCategory) return;

        const parentArticle = contextData[parentArticleCategory].find(
            (article) => article.title === parentArticleTitle,
        );

        if (!parentArticle) {
            global.console.error('component RelatedArticles - Parent article not found');
            return;
        }

        const relatedArticlesTmp = [];

        if (parentArticle.params.related) {
            parentArticle.params.related.forEach((relatedArticleId) => {
                const relatedArticle = findArticleByStoplightId(relatedArticleId, contextData);

                if (relatedArticle) {
                    relatedArticlesTmp.push(relatedArticle);
                }
            });
        }

        if (relatedArticlesTmp.length < 3) {
            const categoryArticles = contextData[parentArticleCategory];
            const filteredCategoryArticles = categoryArticles.filter(
                (article) => article.params.stoplightId !== parentArticle.params.stoplightId
                && !parentArticle.params.related?.includes(article.params.stoplightId),
            );

            relatedArticlesTmp.push(...filteredCategoryArticles.slice(0, 3 - relatedArticlesTmp.length));
        }

        setRelatedArticles(relatedArticlesTmp);
    }, [parentArticleTitle, parentArticleCategory]);

    return (
        <Articles articles={relatedArticles} title="Related Articles" />
    );
};
