import React from 'react';
import styles from './Button.module.scss';

type Props = {
    children: React.ReactNode;
    onClick?: () => void;
};

export function Button({ children, onClick }: Props) {
    return (
        <button type="button" className={styles.btn} onClick={onClick || (() => {})}>
            {children}
        </button>
    );
}
