import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import Data from 'Contexts/DataContext';
import { SearchProvider } from './Contexts/SearchContext';

function App() {
    return (
        <SearchProvider>
            <div className="App">
                <Data>
                    <RouterProvider router={router} />
                </Data>
            </div>
        </SearchProvider>
    );
}

export default App;
