import React, { forwardRef, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './Input.module.scss';

interface Props {
    placeholder?: string;
    className?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input = forwardRef<HTMLInputElement, Props>(
    ({ placeholder = '', className = '', value = '', onChange = () => {} }: Props, ref) => {
        const inputRef = useRef<HTMLInputElement | null>(null);

        useEffect(() => {
            const handleMessage = (event: MessageEvent) => {
                if (event.data && event.data.type === 'triggerAutofocus' && inputRef.current) {
                    inputRef.current.focus();
                }
            };

            window.addEventListener('message', handleMessage);

            return () => {
                window.removeEventListener('message', handleMessage);
            };
        }, []);

        return (
            <input
                type="text"
                className={cn(styles.input, className)}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                ref={(instance) => {
                    inputRef.current = instance;
                    if (typeof ref === 'function') {
                        ref(instance);
                    } else if (ref) {
                        (ref as React.MutableRefObject<HTMLInputElement | null>).current = instance;
                    }
                }}
            />
        );
    },
);

Input.displayName = 'Input';
