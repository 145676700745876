/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-bitwise */

// @ts-ignore
export const decodeBase64 = (s) => {
    const e = {}; let i; let b = 0; let c; let x; let l = 0; let a; let r = ''; const w = String.fromCharCode; const L = s.length;
    const A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    // @ts-ignore
    for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
    for (x = 0; x < L; x++) {
        // @ts-ignore
        c = e[s.charAt(x)];
        b = (b << 6) + c; l += 6;
        // @ts-ignore
        while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
    }
    return r;
};
