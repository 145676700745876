import React from 'react';
import { Button } from 'shared-components/Button';
import styles from './ContactSupport.module.scss';

export function ContactSupport() {
    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>Can&apos;t find what you&apos;re looking for?</p>
            <Button onClick={() => {
                window.parent.postMessage('callContactSupport', '*');
            }}
            >
                Contact Support
            </Button>
        </div>
    );
}
