import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'shared-components/Button';
import styles from 'styles/ErrorPage.module.scss';

export const NotFound = () => {
    return (
        <div className={styles.wrapper}>
            <img src="/assets/images/no-results.svg" alt="Not Found" />
            <div className={styles.title}>No Results Found</div>
            <div className={styles.description}>
                Try adjusting your search or filter to find what you are looking for.
            </div>
            <Link to="/"><Button>Return to home screen</Button></Link>
        </div>
    );
};
