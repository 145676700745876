import React, { useState, useEffect } from 'react';
import { SectionTitle } from 'components/SectionTitle';
import { IArticle } from 'types';
import { useInView } from 'react-intersection-observer';
import styles from './Articles.module.scss';
import { Card } from './Card';

export function Articles({ articles = [], title }: { articles: IArticle[], title: string }) {
    const [visibleCount, setVisibleCount] = useState(12);
    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView && visibleCount < articles.length) {
            setVisibleCount((prevCount) => prevCount + 12);
        }
    }, [inView, visibleCount, articles.length]);

    return (
        <section className={styles.wrapper}>
            <SectionTitle title={title} className={styles.title} />

            <div className={styles.cards}>
                {articles.slice(0, visibleCount).map((article) => (
                    <Card key={article.title} {...article} />
                ))}
            </div>

            {visibleCount < articles.length && (
                <div ref={ref} className={styles.loader}>Loading more...</div>
            )}
        </section>
    );
}
