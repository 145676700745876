import React from 'react';
import { Container } from 'components/Container';
import { Header } from 'sections/Header';
import { ScrollRestoration } from 'react-router-dom';

export const Layout = ({ children }: { children: React.ReactNode}) => {
    return (
        <>
            <ScrollRestoration />
            <Header />
            <Container>
                {children}
            </Container>
        </>
    );
};
