import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './BackToLink.module.scss';

type Props = {
    marginBottom?: string | number,
    marginLeft?: string | number,
    marginRight?: string | number,
    marginTop?: string | number,
    to?: string
}

const BackToLink = ({
    marginBottom = 10,
    marginLeft = 0,
    marginRight = 0,
    marginTop = 10,
} : Props) => {
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <Link
                to="/"
                style={{
                    marginBottom,
                    marginLeft,
                    marginRight,
                    marginTop,
                }}
                onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }}
            >
                ←&nbsp;
                Back
            </Link>
        </div>
    );
};

export default BackToLink;
