import React, { createContext, useState, useContext, ReactNode } from 'react';

interface SearchContextProps {
    searchInput: string | null;
    setSearchInput: (value: string | null) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const useSearchContext = () => {
    const context = useContext(SearchContext);

    if (!context) {
        throw new Error('useSearchContext must be used within an InputProvider');
    }
    return context;
};

export const SearchProvider = ({ children }: { children: ReactNode }) => {
    const [searchInput, setSearchInput] = useState<string | null>('');

    return (
        <SearchContext.Provider value={{ searchInput, setSearchInput }}>
            {children}
        </SearchContext.Provider>
    );
};
