import React, { FunctionComponent, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import styles from './Card.module.scss';

interface CardProps {
   title : string;
   icon: FunctionComponent<SVGProps<SVGSVGElement>>;
   url: string;
}

export function Card({ title, icon: Icon, url }: CardProps) {
    const to = `/General/${url}`;

    return (
        <Link to={to} className={styles.wrapper}>
            <div className={styles.iconWrapper}><Icon /></div>
            <h4 className={styles.title}>{title}</h4>
        </Link>
    );
}
