import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { QuickStart } from 'sections/QuickStart';
import { Article } from 'sections/Article';
import { Category } from 'sections/Category';
import { ArticlesMainPage } from 'sections/Articles/ArticlesMainPage';
import { UnknownError } from 'components/ErrorPage';
import { SearchResults } from 'components/SearchResults';
import { Layout } from 'sections/Layout';

export const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Layout>
                <QuickStart />
                <ArticlesMainPage title="Documentation Articles" />
            </Layout>
        ),
    },
    {
        path: '/search',
        element: (
            <Layout>
                <SearchResults />
            </Layout>
        ),
    },
    {
        path: '/:category',
        element: (
            <Layout>
                <Category />
            </Layout>
        ),
    },
    {
        path: '/:category/:article',
        element: (
            <Layout>
                <Article />
            </Layout>
        ),
    },
    {
        path: '/error',
        element: (
            <Layout>
                <UnknownError />
            </Layout>
        ),
    },
    {
        path: '*',
        element: (
            <Layout>
                <UnknownError />
            </Layout>
        ),
    },
]);
