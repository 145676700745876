import React, { useContext, useEffect } from 'react';
import { SectionTitle } from 'components/SectionTitle';
import { DataContext } from 'Contexts/DataContext';
import { USER_ROLES } from 'constant';
import { ReactComponent as RocketIcon } from './icons/rocket.svg';
import { ReactComponent as FAQIcon } from './icons/faq.svg';
import { ReactComponent as GlossaryIcon } from './icons/glossary.svg';
import { Card } from './Card';
import styles from './QuickStart.module.scss';

const guidesIds = {
    gettingStarted: 'getting-started',
    faq: 'faq',
    glossary: 'glossary',
};

const roleUrls = {
    [guidesIds.gettingStarted]: {
        [USER_ROLES.ASSET_MANAGEMENT]: 'Getting Started - Asset Management',
        [USER_ROLES.SHIPMENT_MANAGEMENT]: 'Getting Started - Shipment Management',
    },
    [guidesIds.faq]: {
        [USER_ROLES.ASSET_MANAGEMENT]: 'FAQ - Asset Management',
        [USER_ROLES.SHIPMENT_MANAGEMENT]: 'FAQ - Shipment Management',
    },
    [guidesIds.glossary]: {
        [USER_ROLES.ASSET_MANAGEMENT]: 'Glossary - Asset Management',
        [USER_ROLES.SHIPMENT_MANAGEMENT]: 'Glossary - Shipment Management',
    },
};

const guides = [
    {
        id: guidesIds.gettingStarted,
        title: 'Getting Started',
        description: 'Everything you need to know to start working with SECURE',
        icon: RocketIcon,
        url: 'not-found',
    },
    {
        id: guidesIds.faq,
        title: 'FAQ',
        description: 'Get answers to the most frequently asked questions',
        icon: FAQIcon,
        url: 'not-found',
    },
    {
        id: guidesIds.glossary,
        title: 'Glossary',
        description: 'Get familiar with commonly used terms',
        icon: GlossaryIcon,
        url: 'not-found',
    },
];

const setRoleUrl = (userRoles: string[], role: string) => {
    if (userRoles.includes(role)) {
        guides.forEach((guide) => {
            const roleUrl = roleUrls[guide.id][role];

            if (roleUrl) {
                guide.url = roleUrl;
            }
        });
    }
};

export function QuickStart() {
    const { userRoles } = useContext(DataContext);

    global.console.log('🚀 ~ QuickStart ~ userRoles:', userRoles);

    useEffect(() => {
        if (userRoles.includes(USER_ROLES.ASSET_MANAGEMENT)) {
            setRoleUrl(userRoles, USER_ROLES.ASSET_MANAGEMENT);
        } else if (userRoles.includes(USER_ROLES.SHIPMENT_MANAGEMENT)) {
            setRoleUrl(userRoles, USER_ROLES.SHIPMENT_MANAGEMENT);
        }
    }, [userRoles]);

    return (
        <section className={styles.wrapper}>
            <SectionTitle title="Quickstart Guides" className={styles.title} />

            <div className={styles.cards}>
                {guides.map((guide) => (<Card key={guide.id} {...guide} />))}
            </div>
        </section>
    );
}
