import icons from 'shared-components/icons';

export const language = 'en';

export const categories = {
    video: 'video',
    productRelease: 'product-release',
    trackAndTrace: 'track-and-trace',
    company: 'company',
    assetManagement: 'asset-management',
    system: 'system',
    default: 'default',
};

export const categoryTitles = {
    [categories.video]: 'Video',
    [categories.productRelease]: 'Product Release',
    [categories.trackAndTrace]: 'Track and Trace',
    [categories.company]: 'Company',
    [categories.assetManagement]: 'Asset Management',
    [categories.system]: 'System',
    [categories.default]: 'No category',
};

export const categoryIcons = {
    [categories.video]: icons.video,
    [categories.productRelease]: icons.approvals,
    [categories.trackAndTrace]: icons.orders,
    [categories.company]: icons.myCompany,
    [categories.assetManagement]: icons.assetManagement,
    [categories.system]: icons.system,
    [categories.default]: icons.system,
};

export const categoryBg = {
    [categories.productRelease]: icons.approvals_light,
    [categories.trackAndTrace]: icons.orders_light,
    [categories.company]: icons.myCompany_light,
    [categories.assetManagement]: icons.assetManagement_light,
    [categories.system]: icons.system_light,
    [categories.default]: icons.system_light,
    [categories.video]: icons.video_light,
};

export const USER_ROLES = {
    BASIC_ACCESS: 'BASIC_ACCESS',
    ASSET_MANAGEMENT: 'ASSET_MANAGEMENT',
    SHIPMENT_MANAGEMENT: 'SHIPMENT_MANAGEMENT',
};

export const documentLocationUrl = document.location.host.toLocaleLowerCase();
