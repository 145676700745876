/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { language } from 'constant';
import { UnknownError } from 'components/ErrorPage';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ContactSupport } from 'sections/ContactSupport';
import styles from 'styles/MainStyles.module.scss';
import BackToLink from 'shared-components/BackToLink';
import { RelatedArticles } from 'sections/RelatedArticles';

let isFetching = false;

export function Article() {
    const { category, article } = useParams();
    const [htmlFileString, setHtmlFileString] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchHtml = async () => {
        if (isFetching) return;
        isFetching = true;
        try {
            setIsLoading(true);
            const data = await fetch(`/articles/${language}/${category}/${article}.html`);
            const htmlString = await data.text();

            if (htmlString.includes('404') || htmlString.includes('<div id="root"></div>')) {
                setHtmlFileString('');
                return;
            }

            if (!htmlString.includes('body')) {
                setHtmlFileString(htmlString);
                return;
            }

            const bodyContentRegex = /<body.*?>([\s\S]*?)<\/body>/;
            const match = htmlString.match(bodyContentRegex);

            if (match && match[1]) {
                setHtmlFileString(match[1]);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error:', error);
        } finally {
            setIsLoading(false);
            isFetching = false;
        }
    };

    useEffect(() => {
        fetchHtml();
    }, [category, article]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!htmlFileString && !isLoading) {
        return <UnknownError />;
    }

    return (
        <article className={styles.articleClass}>
            <Breadcrumbs />
            <BackToLink />
            <div dangerouslySetInnerHTML={{ __html: htmlFileString }} />
            <ContactSupport />
            {category && article && <RelatedArticles parentArticleCategory={category} parentArticleTitle={article} />}
        </article>
    );
}
